<template>
	<div>
		<v-card class="d-inline-flex flex-wrap pa-2" flat outlined>
			<a style="color: #86ae41" @click="approvePost" class="mx-3">{{
				isApproved ? "Unapprove Post" : "Approve Post"
			}}</a
			>
			<a style="color: #86ae41" @click="viewOwningUser" class="mx-3"
				>View User</a
			>
		</v-card>
	</div>
</template>

<script>
export default {
	name: "adminPostActionNav",
	props: ["isApproved", "createdBy"],
	data() {
		return {
			loading: false
		};
	},
	methods: {
		async approvePost() {
			this.loading = true;

			if (!this.isApproved) {
				await this.$store.dispatch(
					"approvePost",
					this.$route.params.id
				);
			} else {
				await this.$store.dispatch(
					"unapprovePost",
					this.$route.params.id
				);
			}

			this.loading = false;
			this.$router.go(this.$router.currentRoute);
		},
		viewOwningUser() {
			window.open("/admin/user/" + this.createdBy);
		}
	}
};
</script>